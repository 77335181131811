import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import { axiosPlugIn, appPlugIn } from '@/plugins/plugInAll';
import { decodeText } from "@/utils/cipher";
Vue.config.productionTip = false;
//use plugIn
Vue.use(axiosPlugIn);
Vue.use(appPlugIn);
new Vue({
    router,
    store,
    vuetify,
    render: h => h(App),
    created() {
        const userLocal = localStorage.getItem("user");
        if (userLocal) {
            const userData = JSON.parse(decodeText(userLocal));
            store.dispatch("userSignIn", userData).then(() => {
                store.dispatch("loadMenus");
            });
        }
        else {
            store.dispatch("clearUser").then(() => {
                localStorage.clear();
            });
        }
    },
}).$mount('#app');
