import axios from 'axios';
import store from '@/store';
import { decodeText } from "@/utils/cipher";
const axiosAPI = axios.create();
axiosAPI.interceptors.request.use(function (config) {
    let token = "";
    const userLocal = localStorage.getItem("user");
    if (userLocal) {
        const userData = JSON.parse(decodeText(userLocal));
        token = userData.token;
    }
    config.baseURL = process.env.VUE_APP_MAIN_URL;
    config.headers = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + `${token}`
    };
    return config;
}, function (error) {
    return Promise.reject(error);
});
axiosAPI.interceptors.response.use(function (response) {
    return response;
}, function (error) {
    if (error.response) {
        const { status } = error.response;
        if (status === 401) {
            store.dispatch("sessionExprire").then(() => {
                localStorage.clear();
            });
        }
    }
    return Promise.reject(error);
});
export default axiosAPI;
