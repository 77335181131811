const reportRoute = () => {
    return [
        {
            path: "/productReportExcel",
            component: "ProductReportExcel",
            folder: "Document/Report",
        },
        {
            path: "/saleGroupAllReport",
            component: "SaleGroupAllReport",
            folder: "Document/Report",
        },
        {
            path: "/saleByDateReport",
            component: "SaleByDateReport",
            folder: "Document/Report",
        },
        {
            path: "/saleGroupReport",
            component: "SaleGroupReport",
            folder: "Document/Report",
        },
        {
            path: "/listReport",
            component: "ListReport",
            folder: "Document/Report",
        },
    ];
};
export default reportRoute;
