const masterRoute = () => {
    return [
        {
            path: "/listReceipt",
            component: "ListReceipt",
            folder: "Document/Receipt",
        },
        {
            path: "/pageReceipt/:id",
            component: "PageReceipt",
            folder: "Document/Receipt",
        },
        {
            path: "/pageReceiptUpload",
            component: "PageReceiptUpload",
            folder: "Document/Receipt",
        },
        {
            path: "/pageSale",
            component: "PageSale",
            folder: "Document/Sale",
        },
        {
            path: "/listSale",
            component: "ListSale",
            folder: "Document/Sale",
        },
        {
            path: "/listAdjust",
            component: "ListAdjust",
            folder: "Document/Adjust",
        },
        {
            path: "/pageAdjust/:id",
            component: "PageAdjust",
            folder: "Document/Adjust",
        },
    ];
};
export default masterRoute;
