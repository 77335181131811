const masterRoute = () => {
    return [
        {
            path: "/listCompany",
            component: "ListCompany",
            folder: "Master/Company",
        },
        {
            path: "/pageCompany/:id",
            component: "PageCompany",
            folder: "Master/Company",
        },
        {
            path: "/listProduct",
            component: "ListProduct",
            folder: "Master/Product",
        },
        {
            path: "/pageProduct/:id",
            component: "PageProduct",
            folder: "Master/Product",
        },
        {
            path: "/listCustomer",
            component: "ListCustomer",
            folder: "Master/Customer",
        },
        {
            path: "/pageCustomer/:id",
            component: "PageCustomer",
            folder: "Master/Customer",
        },
        {
            path: "/listSupplier",
            component: "ListSupplier",
            folder: "Master/Supplier",
        },
        {
            path: "/pageSupplier/:id",
            component: "PageSupplier",
            folder: "Master/Supplier",
        },
        {
            path: "/listBrand",
            component: "ListBrand",
            folder: "Master/Brand",
        },
        {
            path: "/pageBrand/:id",
            component: "PageBrand",
            folder: "Master/Brand",
        },
        {
            path: "/listCatalog",
            component: "ListCatalog",
            folder: "Master/Catalog",
        },
        {
            path: "/pageCatalog/:id",
            component: "PageCatalog",
            folder: "Master/Catalog",
        },
        {
            path: "/listTruck",
            component: "ListTruck",
            folder: "Master/Truck",
        },
        {
            path: "/pageTruck/:id",
            component: "PageTruck",
            folder: "Master/Truck",
        },
        {
            path: "/listCustomer",
            component: "ListCustomer",
            folder: "Master/Customer",
        },
        {
            path: "/pageCustomer/:id",
            component: "PageCustomer",
            folder: "Master/Customer",
        },
        {
            path: "/listTour",
            component: "ListTour",
            folder: "Master/Tour",
        },
        {
            path: "/pageTour/:id",
            component: "PageTour",
            folder: "Master/Tour",
        },
        {
            path: "/listTourDetail/:id",
            component: "ListTourDetail",
            folder: "Master/Tour",
        },
        {
            path: "/pageTourDetail/:id",
            component: "PageTourDetail",
            folder: "Master/Tour",
        },
        {
            path: "/listRoutePricePoint",
            component: "ListRoutePricePoint",
            folder: "Master/RoutePricePoint",
        },
        {
            path: "/pageRoutePricePoint/:id",
            component: "PageRoutePricePoint",
            folder: "Master/RoutePricePoint",
        },
    ];
};
export default masterRoute;
