import cryptoJS from 'crypto-js';
export const encodeText = (text) => {
    const textEncode = cryptoJS.AES.encrypt(text, process.env.VUE_APP_SECRET_KEY).toString();
    return textEncode;
};
export const decodeText = (encodeText) => {
    const bytes = cryptoJS.AES.decrypt(encodeText, process.env.VUE_APP_SECRET_KEY);
    const decryptedData = bytes.toString(cryptoJS.enc.Utf8);
    return decryptedData;
};
