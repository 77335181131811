import Vue from "vue";
import Vuex from "vuex";
import axiosAPI from "@/utils/axios";
import allRoute from "@/router/allRoute";
Vue.use(Vuex);
const loopGroup = (groups) => {
    const routes = [...allRoute()];
    const menus = [];
    for (let index = 0; index < groups.length; index++) {
        const menu = groups[index];
        if (!menu.flgGroup) {
            for (let index = 0; index < routes.length; index++) {
                const route = routes[index];
                if (route.component === menu.menuCode) {
                    const menuData = {
                        active: false,
                        menuDetailId: menu.menuDetailId,
                        menuDetailIcon: menu.menuDetailIcon,
                        menuDetailName: menu.menuDetailName,
                        menuLink: route.path,
                        menuType: menu.menuType,
                        flgGroup: false,
                        menuList: [],
                    };
                    menus.push(menuData);
                    break;
                }
            }
        }
        else {
            const menuData = {
                active: false,
                menuDetailId: menu.menuDetailId,
                menuDetailName: menu.menuDetailName,
                menuDetailIcon: menu.menuDetailIcon,
                menuLink: "",
                menuType: "",
                flgGroup: true,
                menuList: loopGroup(menu.menuList),
            };
            menus.push(menuData);
        }
    }
    return menus;
};
export default new Vuex.Store({
    state: {
        userData: {
            userId: 0,
            username: "",
            userfullName: "",
            groupMenuId: 0,
            token: "",
            flgCreate: false,
            flgEdit: false,
            flgRemove: false,
            flgConfirm: false,
            flgPrint: false,
            customerId: 0,
        },
        isLoading: false,
        isSessionExprire: false,
        isPermissionDenied: false,
        textPermissionDenied: "",
        menusData: [],
        flgDialogAlert: false,
        massageDialogAlert: "",
        taskError: {
            isError: false,
            errorMassage: "",
        },
    },
    getters: {
        user(state) {
            return state.userData;
        },
        loading(state) {
            return state.isLoading;
        },
        sessionExprire(state) {
            return state.isSessionExprire;
        },
        menus(state) {
            return state.menusData;
        },
        permissionDenied(state) {
            return state.isPermissionDenied;
        },
        textPermissionDenied(state) {
            return state.textPermissionDenied;
        },
        flgDialogAlert(state) {
            return state.flgDialogAlert;
        },
        massageDialogAlert(state) {
            return state.massageDialogAlert;
        },
        taskError(state) {
            return state.taskError.isError;
        },
        taskErrorMassage(state) {
            return state.taskError.errorMassage;
        },
    },
    mutations: {
        setUserLogin(state, payload) {
            state.userData = payload;
            state.isSessionExprire = false;
        },
        setUserLogout(state) {
            state.userData = {
                userId: 0,
                username: "",
                userfullName: "",
                groupMenuId: 0,
                token: "",
                flgCreate: false,
                flgEdit: false,
                flgRemove: false,
                flgConfirm: false,
                flgPrint: false,
                customerId: 0,
            };
            state.isSessionExprire = false;
        },
        setLoadingOn(state) {
            state.isLoading = true;
        },
        setLoadingOff(state) {
            state.isLoading = false;
        },
        setSessionExprire(state) {
            state.isSessionExprire = true;
        },
        setMenus(state, payload) {
            state.menusData = payload;
        },
        setPermissionDenied(state, payload) {
            state.isPermissionDenied = payload.flg;
            state.textPermissionDenied = payload.mode;
        },
        setDialogAlertToggle(state, payload) {
            state.flgDialogAlert = !state.flgDialogAlert;
            state.massageDialogAlert = payload;
        },
        setTaskErrorToggle(state, payload) {
            state.taskError.isError = payload.flg;
            state.taskError.errorMassage = payload.massage;
        },
    },
    actions: {
        userSignIn({ commit }, payload) {
            commit("setUserLogin", payload);
        },
        userSignOut({ commit }) {
            commit("setUserLogout");
        },
        clearUser({ commit }) {
            commit("setUserLogout");
        },
        loadingOn({ commit }) {
            commit("setLoadingOn");
        },
        loadingOff({ commit }) {
            commit("setLoadingOff");
        },
        sessionExprire({ commit }) {
            commit("setSessionExprire");
        },
        dialogAlert({ commit }, payload) {
            commit("setDialogAlertToggle", payload);
        },
        taskError({ commit }, payload) {
            commit("setTaskErrorToggle", payload);
        },
        loadMenus({ state, commit }) {
            commit("setLoadingOn");
            axiosAPI
                .post("/groupMenu/genMenu", {
                groupMenuId: state.userData.groupMenuId,
            })
                .then((response) => {
                const groups = response.data.responseObject;
                const menus = loopGroup(groups);
                commit("setMenus", menus);
                commit("setLoadingOff");
            })
                .catch(() => {
                commit("setLoadingOff");
            });
        },
        checkPermissionDenied({ state, commit }, payload) {
            if (payload === "ADD") {
                if (!state.userData.flgCreate) {
                    commit("setPermissionDenied", {
                        flg: true,
                        mode: "เพิ่มข้อมูล",
                    });
                    throw "PermissionDenied ADD!";
                }
            }
            else if (payload === "EDIT") {
                if (!state.userData.flgEdit) {
                    commit("setPermissionDenied", {
                        flg: true,
                        mode: "แก้ไขข้อมูล",
                    });
                    throw "PermissionDenied EDIT!";
                }
            }
            else if (payload === "REMOVE") {
                if (!state.userData.flgRemove) {
                    commit("setPermissionDenied", {
                        flg: true,
                        mode: "ลบข้อมูล",
                    });
                    throw "PermissionDenied REMOVE!";
                }
            }
            else if (payload === "CONFIRM") {
                if (!state.userData.flgConfirm) {
                    commit("setPermissionDenied", {
                        flg: true,
                        mode: "ยืนยันข้อมูล",
                    });
                    throw "PermissionDenied CONFIRM!";
                }
            }
            else if (payload === "PRINT") {
                if (!state.userData.flgPrint) {
                    commit("setPermissionDenied", {
                        flg: true,
                        mode: "พิมพ์ข้อมูล",
                    });
                    throw "PermissionDenied PRINT!";
                }
            }
            else {
                throw "Mode Permission Not Found!";
            }
        },
        closePermissionDenied({ commit }) {
            commit("setPermissionDenied", {
                flg: false,
                mode: "",
            });
        },
    },
    modules: {},
});
