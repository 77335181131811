import Vue from "vue";
import VueRouter from "vue-router";
import allRoute from "@/router/allRoute";
Vue.use(VueRouter);
const routerOptions = [
    {
        path: "/",
        component: "Admin",
        children: [...allRoute()],
    },
    {
        path: "/login",
        component: "Login",
        children: [],
    },
];
const routes = routerOptions.map((route) => {
    return {
        path: route.path,
        component: () => import(`@/views/${route.component}.vue`),
        children: route.children.map((child) => {
            return {
                path: child.path,
                component: () => import(`@/views/${child.folder}/${child.component}.vue`),
            };
        }),
    };
});
//add redirect all to home
routes.push({ path: "*", redirect: "/" });
const router = new VueRouter({
    routes,
});
router.beforeEach((to, from, next) => {
    const publicPages = ["/login"];
    const authRequired = !publicPages.includes(to.path);
    const loggedIn = localStorage.getItem("user");
    if (!loggedIn) {
        if (authRequired) {
            next("/login");
        }
        else {
            next();
        }
    }
    else {
        next();
    }
});
export default router;
