import store from "@/store";
import axiosAPI from "@/utils/axios";
import { formatTh, formatTime } from "@/utils/date";
export const axiosPlugIn = {
    install(Vue) {
        Vue.prototype.$axios = axiosAPI;
    },
};
export const appPlugIn = {
    install(Vue) {
        Vue.filter("formatDateTH", function (value) {
            return formatTh(value);
        });
        Vue.filter("formatTimeUse", function (value) {
            return formatTime(value);
        });
        Vue.filter("formatNumber", function (number) {
            return number.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        });
        Vue.prototype.$app = {
            loading: {
                on: () => store.dispatch("loadingOn"),
                off: () => store.dispatch("loadingOff"),
            },
            alert: (massage) => {
                store.dispatch("dialogAlert", massage);
            },
            checkPermission: {
                add: (onPass) => store
                    .dispatch("checkPermissionDenied", "ADD")
                    .then(() => {
                    onPass();
                })
                    .catch((error) => {
                    console.log(error);
                }),
                edit: (onPass) => store
                    .dispatch("checkPermissionDenied", "EDIT")
                    .then(() => {
                    onPass();
                })
                    .catch((error) => {
                    console.log(error);
                }),
                remove: (onPass) => store
                    .dispatch("checkPermissionDenied", "REMOVE")
                    .then(() => {
                    onPass();
                })
                    .catch((error) => {
                    console.log(error);
                }),
                confirm: (onPass) => store
                    .dispatch("checkPermissionDenied", "CONFIRM")
                    .then(() => {
                    onPass();
                })
                    .catch((error) => {
                    console.log(error);
                }),
                print: (onPass) => store
                    .dispatch("checkPermissionDenied", "PRINT")
                    .then(() => {
                    onPass();
                })
                    .catch((error) => {
                    console.log(error);
                }),
            },
            task: async (onProcess) => {
                try {
                    store.dispatch("loadingOn");
                    return await onProcess();
                }
                catch (error) {
                    console.log(error);
                    store.dispatch("taskError", { flg: true, massage: error });
                }
                finally {
                    store.dispatch("loadingOff");
                }
            },
            checkApiError: (response) => {
                try {
                    if (response.status == 422) {
                        if (response.data.responseMessage[0]) {
                            return response.data.responseMessage[0];
                        }
                        else {
                            return "";
                        }
                    }
                    else if (response.status == 500) {
                        if (response.data.customErrorMessage) {
                            return response.data.customErrorMessage;
                        }
                        else {
                            return "";
                        }
                    }
                }
                catch (error) {
                    store.dispatch("checkApiError", { flg: true, massage: error });
                }
            },
            // formatNumber(number: string): string {
            //   return parseFloat(number)
            //     .toFixed(2)
            //     .toString().split( /(?=(?:\d{3})+(?:\.|$))/g ).join( "," );
            // },
            // removeComma(number: string): number {
            //   let numberReplace = number.toString();
            //   numberReplace = numberReplace.replace(/\,/g, "");
            //   return Number(numberReplace);
            // },
        };
    },
};
