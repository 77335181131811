const myRoute = () => {
    return [
        {
            path: '/',
            component: 'Dashboard',
            folder: 'Dashboard',
        },
        {
            path: '/listUsers',
            component: 'ListUsers',
            folder: 'Users',
        },
        {
            path: '/pageUsers/:id',
            component: 'PageUsers',
            folder: 'Users',
        },
        {
            path: '/pageChangePWD',
            component: 'PageChangePWD',
            folder: 'Users',
        },
        {
            path: '/listReference',
            component: 'ListReference',
            folder: 'Reference',
        },
        {
            path: '/pageReference/:id',
            component: 'PageReference',
            folder: 'Reference',
        },
        {
            path: '/pageReferenceDetail/:id',
            component: 'PageReferenceDetail',
            folder: 'Reference',
        },
        {
            path: '/listGroupMenu',
            component: 'ListGroupMenu',
            folder: 'GroupMenu',
        },
        {
            path: '/pageGroupMenu/:id',
            component: 'PageGroupMenu',
            folder: 'GroupMenu',
        },
        {
            path: '/pageGroupMenuDetail/:id',
            component: 'PageGroupMenuDetail',
            folder: 'GroupMenu',
        },
        {
            path: '/listMenu',
            component: 'ListMenu',
            folder: 'Menu',
        },
        {
            path: '/pageMenu/:id',
            component: 'PageMenu',
            folder: 'Menu',
        },
        {
            path: '/pageConfigTMS',
            component: 'PageConfigTMS',
            folder: 'Reference',
        },
    ];
};
export default myRoute;
