import "@fortawesome/fontawesome-free/css/all.css";
import "material-design-icons-iconfont/dist/material-design-icons.css";
import Vue from "vue";
import Vuetify from "vuetify/lib";
import colors from "vuetify/lib/util/colors";
import '@mdi/font/css/materialdesignicons.css';
Vue.use(Vuetify);
export default new Vuetify({
    icons: {
        iconfont: "md" || "fa" || "mdi",
    },
    theme: {
        themes: {
            light: {
                primary: colors.teal.darken2,
                secondary: colors.teal.lighten3,
                accent: colors.teal.base,
            },
        },
    },
});
