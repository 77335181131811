export const dateTimestampToDateEng = (dateTimestamp) => {
    if (!dateTimestamp)
        return "";
    const date = new Date(dateTimestamp);
    return (date.getFullYear() +
        "-" +
        ("0" + (date.getMonth() + 1)).slice(-2) +
        "-" +
        ("0" + (date.getDate())).slice(-2));
};
export const formatTh = (dateString) => {
    if (!dateString)
        return "";
    else {
        const date = dateTimestampToDateEng(dateString);
        if (date.indexOf("-") > -1) {
            const [year, month, day] = date.split("-");
            return `${day}/${month}/${Number(year) + Number(543)}`;
        }
        else {
            const year = date.substring(0, 4);
            const month = date.substring(4, 6);
            const day = date.substring(6, 8);
            return `${day}/${month}/${Number(year) + Number(543)}`;
        }
    }
};
export const formatShortTh = (dateString) => {
    if (!dateString)
        return "";
    else {
        if (dateString.indexOf("-") > -1) {
            const [year, month, day] = dateString.split("-");
            return `${day}/${month}/${(Number(year) + Number(543))
                .toString()
                .substring(2, 4)}`;
        }
        else {
            const year = dateString.substring(0, 4);
            const month = dateString.substring(4, 6);
            const day = dateString.substring(6, 8);
            return `${day}/${month}/${(Number(year) + Number(543))
                .toString()
                .substring(2, 4)}`;
        }
    }
};
export const formatEN = (dateString) => {
    if (!dateString)
        return "";
    else {
        if (dateString.indexOf("-") > -1) {
            const [year, month, day] = dateString.split("-");
            return `${day}/${month}/${Number(year)}`;
        }
        else {
            const year = dateString.substring(0, 4);
            const month = dateString.substring(4, 6);
            const day = dateString.substring(6, 8);
            return `${day}/${month}/${Number(year)}`;
        }
    }
};
export const formatReverseEN = (dateString) => {
    if (!dateString)
        return "";
    else {
        if (dateString.indexOf("-") > -1) {
            const [year, month, day] = dateString.split("-");
            return `${Number(year)}/${month}/${day}`;
        }
        else {
            const year = dateString.substring(0, 4);
            const month = dateString.substring(4, 6);
            const day = dateString.substring(6, 8);
            return `${Number(year)}/${month}/${day}`;
        }
    }
};
export const dateStringThToDateFormat = (dateStringTh) => {
    if (!dateStringTh)
        return "";
    const [day, month, year] = dateStringTh.split("/");
    return `${Number(year) - Number(543)}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
};
export const dateStringThToDate = (dateStringTh) => {
    if (!dateStringTh)
        return "";
    const [day, month, year] = dateStringTh.split("/");
    return `${Number(year) - Number(543)}${month.padStart(2, "0")}${day.padStart(2, "0")}`;
};
export const dateStringToDate = (dateString) => {
    if (!dateString)
        return "";
    const year = dateString.substring(0, 4);
    const month = dateString.substring(4, 6);
    const day = dateString.substring(6, 8);
    return `${Number(year)}-${month}-${day}`;
};
export const dateToDateString = (date) => {
    if (!date)
        return "";
    const [year, month, day] = date.split("-");
    return `${Number(year)}${month.padStart(2, "0")}${day.padStart(2, "0")}`;
};
export const dateStringCurrent = () => {
    return new Date()
        .toISOString()
        .substr(0, 10)
        .replace("-", "");
};
export const dateCurrent = () => {
    return new Date().toISOString().substr(0, 10);
};
export const dateCurrentRevese = (date) => {
    if (!date)
        return "";
    const [year, month, day] = date.split("-");
    return `${day.padStart(2, "0")}-${month.padStart(2, "0")}-${Number(year)}`;
};
export const formatTime = (timeString) => {
    if (!timeString)
        return "";
    else {
        return (timeString.substring(0, 2) +
            ":" +
            timeString.substring(2, 4) +
            ":" +
            timeString.substring(4, 6));
    }
};
export const timeCurrent = () => {
    const today = new Date();
    const time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
    return time;
};
export const formatDateDash = (dateString) => {
    if (!dateString)
        return "";
    else {
        return (dateString.substring(0, 4) +
            "-" +
            dateString.substring(4, 6) +
            "-" +
            dateString.substring(6, 8));
    }
};
export const timeToString = (timeString) => {
    if (!timeString)
        return "";
    return timeString.replace(":", "");
};
